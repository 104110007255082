/**
 * 代理商
 */
// agent("代理商"),
// //子客
// sub("子客"),
// //优化师
// worker_optimizer("优化师"),
// //财务
// worker_finance("财务"),
// //销售
// worker_saler("销售"),
// //其他协作者
// worker_other("其他协作者"),
// ;

import React from "react"
import {
  createFromIconfontCN, TeamOutlined, PicLeftOutlined,
  FundOutlined, IdcardOutlined, CreditCardOutlined, BarChartOutlined, PushpinOutlined,StockOutlined,
  ToolOutlined,HeatMapOutlined,BookOutlined
} from "@ant-design/icons"
const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/a/font_2455017_qzef1kn6sp.js",
});
const env = (window as any).pageVars.app.env ||'prod';
const dmpUrl ={
  pre: 'https://pre-ad-dmp.youku.com',
  prod: 'https://ad-dmp.youku.com'
}
const data = [
  {
    key: "index",
    title: "首页",
    link: "/index",
    code: "index",
    authCode: ["agent", "worker_optimizer", "worker_saler", "worker_other"],
  },
  {
    key: "custom",
    title: "客户",
    code: "custom",
    authCode: ["agent", "worker_optimizer", 'newaccount'],
    children: [
      {
        key: "6302",
        title: "客户管理",
        authCode: ["agent", "worker_optimizer", "worker_saler", "worker_other"],
        icon: () => <TeamOutlined size={20} />,
        children: [
          {
            key: "6303",
            title: "客户列表",
            link: "/customerList",
            authCode: ["agent", "worker_optimizer", "worker_saler", "worker_other"]
          },
          {
            key: "promotionlist",
            title: "推广管理",
            link: "/promotionlist",
            authCode: ["agent", "worker_optimizer", "worker_saler", "worker_other"],
            grey: (logininfo) => {
              return logininfo?.openNewBp
            }
          },
          {
            key: "6487",
            title: "推广组列表",
            link: "/65d5caa9",
            authCode: ["agent", "worker_optimizer", "worker_saler", "worker_other"],
            grey: (logininfo) => {
              return !logininfo?.openNewBp
            }
          },

          {
            key: "6488",
            title: "推广计划列表",
            link: "/24d1366c",
            authCode: ["agent", "worker_optimizer", "worker_saler", "worker_other"],
            grey: (logininfo) => {
              return !logininfo?.openNewBp
            }
          },
          {
            key: "6489",
            title: "推广创意列表",
            link: "/9c73cf72",
            authCode: ["agent", "worker_optimizer", "worker_saler", "worker_other"],
            grey: (logininfo) => {
              return !logininfo.openNewBp
            }
          },
        ],
      },
      {
        key: "6305",
        title: "账号管理",
        authCode: ["agent"],
        icon: () => <IdcardOutlined size={20} />,
        children: [
          {
            key: "6306",
            title: "添加账号和资金分配",
            link: "/072e6a09",
            authCode: ["agent"],
          },
          {
            key: "allocateFunds",
            title: "分配资金额度",
            link: "/allocateFunds",
            hidden: true,
            activeKey: "6306",
            authCode: ["agent"],
          },
          {
            key: "7ee8c731",
            title: "分配资金额度",
            link: "/7ee8c731",
            hidden: true,
            activeKey: "6306",
            authCode: ["agent"],
          },
          {
            key: "rengzhengzhongxin",
            title: "认证中心",
            link: "/e1fed46b",
            hidden: true,
            activeKey: "6306",
            authCode: ["agent"],
          },
          {
            key: "6427",
            title: "协作账号管理",
            link: "/673a9c5e",
            authCode: ["agent"],
          },
          {
            key: "6431",
            title: "帐户信息",
            icon: null,
            link: "/accountInfo",
            authCode: ["agent", 'newaccount'],
          },
        ],
      },
    ],
  },
  {
    key: "data",
    title: "数据",
    code: "data",
    authCode: [
      "agent",
      "worker_optimizer",
      "worker_finance",
      "worker_saler",
      "worker_other",
    ],
    children: [
      {
        key: "6424",
        title: "账号报表",
        icon: () => <CreditCardOutlined size={20} />,
        children: [
          {
            key: "/0d5bb31d",
            title: "代理商帐户",
            link: "/0d5bb31d",
            authCode: ["agent"],
          },
          {
            key: "605ca278",
            title: "子客帐户",
            link: "/605ca278",
            authCode: ["agent", "worker_finance", "worker_optimizer", "worker_saler", "worker_other"],
          },
        ],
      },
      {
        key: "6434",
        title: "投放报表",
        authCode: ["agent", "worker_optimizer", "worker_saler", "worker_other", "worker_finance"],
        icon: () => <BarChartOutlined size={20} />,
        children: [
          {
            key: "projectReport",
            title: "项目报表",
            link: "/projectReport",
            authCode: ["agent", "worker_optimizer", "worker_saler", "worker_other", "worker_finance"],
          },
          {
            key: "adReport",
            title: "广告报表",
            link: "/adReport",
            authCode: ["agent", "worker_optimizer", "worker_saler", "worker_other", "worker_finance"],
          },
          {
            key: "creativeReport",
            title: "新版创意报表",
            link: "/creativeReport",
            authCode: ["agent", "worker_optimizer", "worker_saler", "worker_other", "worker_finance"],
          },
          {
            key: "6435",
            title: "组报表",
            link: "/ba45d6de",
            authCode: ["agent", "worker_optimizer", "worker_saler", "worker_other", "worker_finance"],
          },

          {
            key: "6436",
            title: "计划报表",
            link: "/4b8fec3e",
            authCode: ["agent", "worker_optimizer", "worker_saler", "worker_other", "worker_finance"],
          },

          {
            key: "6437",
            title: "老版创意报表",
            link: "/c25df44d",
            authCode: ["agent", "worker_optimizer", "worker_saler", "worker_other", "worker_finance"],
          },

        ],
      },
      {
        key: "materialReport",
        title: <div><StockOutlined size={20} />&nbsp;&nbsp;素材报表</div>,
        link: "/materialReport",
        authCode: [
          "agent",
          "worker_optimizer",
          "worker_finance",
          "worker_saler",
          "worker_other",
        ],
        grey: (logininfo) => {
          return logininfo?.openLibrary
        }


      },
      {
        key:"specialReport",
        title:"专项报告",
        icon:()=> <BookOutlined size={20}/>,
        authCode:["agent","worker_optimizer","worker_finance","worker_saler","worker_other"],
        children:[
          {
            key: "kuWorryFreeReport",
            title: 'oCPX成本保障报告',
            link: "/kuWorryFreeReport",
            authCode: [
              "agent",
              "worker_optimizer",
              "worker_finance",
              "worker_saler",
              "worker_other",
            ],
            grey: (logininfo) => {
              return logininfo?.compensateV2Switch
            }
          },
          {
            key: "boostAppointReport",
            title: "一键起量报告",
            link: "/boostAppointReport",
            authCode: ["agent", "worker_optimizer", "worker_finance", "worker_saler", "worker_other"],
            grey: (logininfo) => {
              return logininfo?.coldBootSwitch
            }
          }
        ]
      },
      
    ],
  },
  {
    key: "finance",
    title: "财务",
    code: "finance",
    authCode: ["agent", "worker_finance", "worker_other"],
    children: [
      {
        key: "6438",
        title: "在线充值",
        greyTitle:"财务流水",
        link: "/553b757c",
        authCode: ["agent", "worker_finance", "worker_other"],
        showGreyTitle: (logininfo) => {
          return logininfo?.finance
        },
      },
      {
        key: "6439",
        title: "打款信息",
        link: "/5b3ca584",
        authCode: ["agent", "worker_finance", "worker_other"],
        grey: (logininfo) => {
          return !logininfo?.finance
        },
      },
      {
        key: "accountRecharge",
        title: "账户充值",
        link: "/accountRecharge",
        authCode: ["agent", "worker_finance", "worker_other"],
        grey: (logininfo) => {
          return logininfo?.finance
        },
      },
      {
        key: "costprotection",
        title: "成本保障",
        link: "/costprotection",
        authCode: ["agent", "worker_finance", "worker_other"],
        grey: (logininfo) => {
          return logininfo?.compensateSwitch && !logininfo.compensateV2Switch
        },
      },
    ],
  },
  {
    key: "assets",
    title: "资产",
    code: "assets",
    authCode: ["agent", "worker_optimizer", "worker_other"],
    children: [
      {
        key: "6440",
        title: "定向",
        authCode: ["agent", "worker_optimizer", "worker_other"],
        icon: () => <PushpinOutlined size={20} />,
        children: [
          {
            key: "6441",
            title: "自定义人群包",
            link: "/2ef6529f",
            authCode: ["agent", "worker_optimizer", "worker_other"],
            grey: (logininfo) => {
              return !logininfo?.dmpV1
            }
          },
          // {
          //   key: "dmpcrowd",
          //   title: "人群",
          //   link: "/dmpcrowd",
          //   authCode: ["agent", "worker_optimizer", "worker_other"],
          //   grey: (logininfo) => {
          //     return logininfo?.dmpV1
          //   }
          // },
          {
            key: "dmpsite",
            title: 'DMP平台',
            link: dmpUrl[env],
            isBlank:true,
            isNew: true,
            authCode: ["agent", "worker_optimizer", "worker_other"],
            grey: (logininfo) => {
              return logininfo?.dmpV1
            }
          },
        ],
      },
      
      {
        key: "6485",
        title: "素材管理",
        icon: () => <PicLeftOutlined size={20} />,
        authCode: ["agent", "worker_optimizer", "worker_other"],
        children: [
          {
            key: "materialManagement",
            title: "素材授权",
            link: "/materialManagement",
            authCode: ["agent", "worker_optimizer", "worker_other"],
            grey: (logininfo) => {
              return logininfo?.openLibrary
            }
          },
          {
            key: "6486",
            title: "自建站",
            link: "/601e1bdb",
            authCode: ["agent", "worker_optimizer", "worker_other"],
          },
          {
            key: "sitedata",
            title: "自建站数据",
            link: "/73cbfb8f",
            authCode: ["agent", "worker_optimizer", "worker_other"],
            hidden: true,
            activeKey: "6486"
          },

        ],
      },
      {
        key: "6444",
        title: "转化",
        icon: () => <IconFont type="icon-tubiao" size={20} />,
        authCode: ["agent", "worker_optimizer", "worker_other"],
        children: [
          {
            key: "6445",
            title: "转化追踪",
            link: "/493255ca",
            authCode: ["agent", "worker_optimizer", "worker_other"],
          },
          {
            key: "zhuanhuazhuizong",
            title: "转化追踪新建/编辑",
            link: "/v2/8dd949ac",
            hidden: true,
            activeKey: "6445",
          },
        ],
      },
    ],
  },
  {
    key: "tools",
    title: "工具",
    code: "tools",
    authCode: ["agent", "worker_optimizer", "worker_other"],
    children: [
      {
        key: "platformtool",
        title: "平台工具",
        authCode: ["agent"],
        icon: () => <ToolOutlined size={20}/>,
        grey: (logininfo) => {
          return logininfo?.mapiWhiteUser
        },
        children: [
          {
            key: "mapi",
            title: "授权管理",
            link: "/authorizeManagement",
            authCode: ["agent"],
            grey: (logininfo) => {
              return  logininfo?.mapiWhiteUser
            },
          },
        
        ],
      },
      {
        key: "6442",
        title: "流量工具",
        icon: () => <FundOutlined size={20} />,
        authCode: ["agent", "worker_optimizer", "worker_other"],
        children: [
          {
            key: "6443",
            title: "RTA策略管理",
            link: "/45df9d7c",
            authCode: ["agent", "worker_optimizer", "worker_other"],
          },
        ],
      },
      {
        key: "accouttool",
        title: "账户工具",
        icon: () => <IdcardOutlined size={20}/>,
        authCode: ["agent", "worker_optimizer", "worker_other"],
        children: [
          {
            key: "downloadtask",
            title: "下载任务",
            link: "/downloadTask",
            authCode: ["agent", "worker_optimizer", "worker_other"],
          },
        ],
      },
      {
        key: "putingtool",
        title: "投放工具",
        icon: () => <HeatMapOutlined size={20}/>,
        authCode: ["agent", "worker_optimizer"],
        grey: (logininfo) => {
          return logininfo?.diagnosis
        },
        children: [
          {
            key: "accountanalysis",
            title: "账户投放分析",
            link: "/accountAnalysis",
            authCode: ["agent", "worker_optimizer"],
            grey: (logininfo) => {
              return logininfo?.diagnosis
            },
          },
        ],
      },
    ],
  },
]

export default data
