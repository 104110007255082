import React from 'react';
import { Avatar, Typography, Divider, Popover, Button, Image } from 'antd';
import { UserOutlined, TeamOutlined, ContactsOutlined, LogoutOutlined, CaretDownOutlined } from '@ant-design/icons';
const { Text, Link } = Typography;
import vmsService from "./vmsService";
import {
  createFromIconfontCN, PicLeftOutlined,
  FundOutlined, IdcardOutlined, CreditCardOutlined, BarChartOutlined, PushpinOutlined
} from "@ant-design/icons";

const backMap = {
  pre: 'pre-missile-dsp.youku.com',
  prod: 'missile-dsp.youku.com'
}
const roleMap = {
  agent: {
    text: '代理商',
  },
  sub: {
    text: '',
  },
  worker_optimizer: {
    text: '优化师',
  },
  worker_finance: {
    text: '财务',
  },
  worker_saler: {
    text: '销售',
  },
  worker_other: {
    text: '其他协作者',
  }
}
const host = backMap[(window as any).pageVars?.app?.env || 'prod'];

const App = (props) => {
  const { loginInfo } = props;
  const { advertiserName, advertiserId, role, allShow, roleLogoUrl, type } = loginInfo || {};
  const subViewAdvertiserId = new URLSearchParams(window.location.search).get('subViewAdvertiserId');
  const login_out_url = `https://${host}/bucSSOLogout?BACK_URL=https://${host}/api/login/logout_routing/crm`;

  const goLoginOut = () => {
    if (loginInfo.employeeId) {
      vmsService.loginOut().then(res => {
        window.location.href = login_out_url;
      })
    } else {
      window.location.href = login_out_url;
    }
  }

  const content = () => {
    return <div style={{ width: 200 }}>
      <div>
        <Text style={{ color: '#C1C4CF', fontSize: 12, marginBottom: 8, display: 'block' }}>当前登录账号</Text>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Image src={roleLogoUrl} preview={false} width={20} />
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
            <Text style={{ color: '#333333', fontSize: 12 }}>{roleMap[role]?.text ? roleMap[role]?.text + '账号：' : ''}{advertiserName}</Text>
            <Text style={{ color: '#C1C4CF', fontSize: 12 }}>ID:{advertiserId}</Text>
          </div>
          {
            subViewAdvertiserId ? <>
              <Text style={{ color: '#C1C4CF', fontSize: 12, marginBottom: 8, display: 'block' }}>当前访问账号</Text>
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                <Text style={{ color: '#C1C4CF', fontSize: 12 }}>ID:{advertiserId}</Text>
              </div></> : null
          }
        </div>
      </div>
      <Divider style={{ margin: '8px 0px' }} />
      <div style={{ display: 'flex', flexDirection: 'column', color: '#333333', }}>
        {type == 'agent' && <Link style={{ margin: '6px', color: '#333333' }} href='/accountInfo'><UserOutlined style={{ marginRight: 8 }} />帐户信息</Link>}
        {allShow && type == 'agent' && <Link style={{ margin: '4px', color: '#333333' }} href='/customerList'><TeamOutlined style={{ marginRight: 8 }} />客户管理</Link>}
        {allShow && type == 'agent' && <Link style={{ margin: '4px', color: '#333333' }} href='/673a9c5e'><ContactsOutlined style={{ marginRight: 8 }} />协作账号管理</Link>}
        <Link style={{ margin: '4px', color: '#333333' }} onClick={goLoginOut}><LogoutOutlined style={{ marginRight: 8 }} />退出登录</Link>
      </div>
    </div>
  }

  return (
    <div style={{}}>
      <div style={{ display: 'flex', alignItems: 'center', height: 53 }}>
        <Link style={{ display: 'flex', alignItems: 'center', color: '#8D8A8A', fontSize: 12 }} href='https://xinruishi.yuque.com/gl0g2u/info' target='_blank'>
          <Image src='https://gw.alicdn.com/imgextra/i4/O1CN01qmnepC27OV7scAFhC_!!6000000007787-2-tps-54-64.png' width={14} preview={false} />
          <span style={{ marginLeft: 4 }}>帮助引导</span></Link>
        <Divider type="vertical" style={{ margin: '0 18px' }} />
        <Popover
          content={content()}
          arrow={false}
        // getPopupContainer={(el) => document.getElementById('app') as any || el}
        >
          <div style={{ display: 'flex', alignItems: 'center', minWidth: 200, height: 53 }}>
            <Image src={roleLogoUrl} preview={false} width={32} />
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8, flex: 1 }}>
              <Text type="secondary" style={{ color: '#333333', fontSize: 12 }}>{advertiserName}</Text>
              <Text type="secondary" style={{ color: '#C1C4CF', fontSize: 12 }}>ID:{advertiserId}</Text>
            </div>
            <CaretDownOutlined style={{ marginLeft: 8, fontSize: 12, color: '#bfbfbf' }} />
          </div>
        </Popover>
      </div>

    </div>
  );
}

export default App;